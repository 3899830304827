import { PendingReturnOrderProduct } from '@/GeneratedTypes/PendingReturnOrderProduct'
export function getEmptyPendingReturnOrderProduct(): PendingReturnOrderProduct {
  return {
    leagueID: 0,
    accountNumber: '',
    id: 0,
    typeProgramID: '',
    productID: 0,
    typeColorID: 'N/A',
    typeSizeID: 'N/A',
    quantity: 0,
    typeReturnReasonID: '',
    cargo: '',
  }
}
