import { returnReasonTypes } from '@/store/returnReasonTypes'

export function getReturnReasonDescription(reason: string | null) {
  let retval = '???'
  const upwardReason = returnReasonTypes.state.items.find((x) => x.upwardTypeID === reason)
  if (upwardReason) {
    retval = upwardReason.description!
  }

  return retval
}

export function cargoIsRequired(reason: string | null) {
  return reason === 'PLAYER_DID_NOT_PLAY'
}
