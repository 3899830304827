

































import { computed, defineComponent, PropType } from '@vue/composition-api'
import { PendingReturnOrderProductInfo } from '@/GeneratedTypes/ListInfo/PendingReturnOrderProductInfo'
import { filterNA } from '@/models/Order/OrderProduct'
import { cargoIsRequired } from '@/lib/common/upwardReturnReasonTypes'

interface ReturnOrderCartProduct extends PendingReturnOrderProductInfo {
  reason: string
  name: string
  color: string
  size: string
  pendingProduct: PendingReturnOrderProductInfo
}

export default defineComponent({
  name: 'ReturnOrderCart',
  components: {},
  props: {
    readOnly: {
      type: Boolean,
      required: false,
      default: true,
    },
    title: { type: String, default: '', required: false },
    pendingReturnProducts: { type: Array as PropType<PendingReturnOrderProductInfo[]>, required: true },
  },
  setup(props, ctx) {
    //const upwId = computed(() => store.getters.leagueAbstraction.currentItem.upwardLeagueID ?? '')
    //const account = computed(() => store.getters.authorization.firstAccountNumber)

    const products = computed(() => {
      // merge pending items with information needed for display (like cmsProductName, cost, etc)
      return (
        props.pendingReturnProducts.map((p) => {
          return returnOrderCartProduct(p)
        }) ?? []
      )
    })

    function rowKey(product: ReturnOrderCartProduct) {
      const p = product.pendingProduct
      return `${p.id}-${p.typeColorID}-${p.typeSizeID}-${p.typeReturnReasonID}`
    }

    function returnOrderCartProduct(pending: PendingReturnOrderProductInfo) {
      return {
        pendingProduct: pending,
        reason: pending.returnReasonDescription,
        name: pending.cmsProductName,
        color: filterNA(pending.colorDescription ?? ''),
        size: filterNA(pending.sizeDescription ?? '') ? `(${filterNA(pending.sizeDescription ?? '')})` : '',
      } as ReturnOrderCartProduct
    }

    const total = computed(() => products.value.reduce((t, x) => t + x.pendingProduct.quantity, 0))

    function remove(p: ReturnOrderCartProduct) {
      if (!props.readOnly) {
        ctx.emit('remove', p.pendingProduct)
      }
    }

    function showCargo(product: ReturnOrderCartProduct) {
      return cargoIsRequired(product.pendingProduct.typeReturnReasonID)
    }

    return { products, remove, total, rowKey, showCargo }
  },
})
