



































import { computed, defineComponent, PropType, ref } from '@vue/composition-api'
import { filterNA } from '@/models/Order/OrderProduct'
import { maxStringLength } from '@/filters/maxStringLength'
import { getEmptyProductOfferingInfo } from '@/lib/support/models/ProductOfferingInfo/ProductOfferingInfo'
import { OrderProduct } from '@/models/Order/OrderProduct'
import { OrderProductSize } from '@/models/Order/OrderProductSize'

export default defineComponent({
  props: {
    product: {
      type: Object as PropType<OrderProduct>,
      required: false,
      default: getEmptyProductOfferingInfo,
    },
    small: { type: Boolean, required: false, default: true },
  },
  setup(props) {
    const currentImg = ref(0)

    const images = computed(() =>
      !props.product.cmsProductImageUrls || props.product.cmsProductImageUrls.length === 0
        ? ['/img/noImgSmall.png']
        : props.product.cmsProductImageUrls
    )

    function getPreviousLabel() {
      if (props.product.productColorSizes) {
        if (props.product.productColorSizes.length < 2) {
          if (props.product.productColorSizes?.length === 0) {
            return 'This should not happen. remove if found'
          } else {
            //so there is only one
            const color = filterNA(props.product.productColorSizes[0].colorDescription)
              ? filterNA(props.product.productColorSizes[0].colorDescription) + ' '
              : ''
            const size = filterNA(props.product.productColorSizes[0].sizeDescription)
              ? filterNA(props.product.productColorSizes[0].sizeDescription) + ' '
              : ''
            return (
              'Previously ordered quantity: ' +
              color +
              size +
              props.product.productColorSizes[0].previouslyOrdered +
              'x'
            )
          }
        } else {
          return 'Previously ordered quantities: '
        }
      } else {
        return ''
      }
    }

    function description(productDescription: string): string {
      if (props.small) return maxStringLength(productDescription, 200)
      return productDescription
    }

    function rowKey(productColorSize: OrderProductSize) {
      return `${productColorSize.typeColorID}-${productColorSize.typeSizeID}`
    }

    return {
      currentImg,
      images,
      description,
      rowKey,
      filterNA,
      getPreviousLabel,
    }
  },
})
