

































































































import { computed, defineComponent, onMounted, PropType, ref, watch } from '@vue/composition-api'
import { filterNA, OrderProduct } from '@/models/Order/OrderProduct'
import Modal from '@/components/Modal.vue'
import ReturnProductDetail from '@/components/Orders/ReturnProductDetail.vue'
import SelectInput from '@/elements/SelectInput.vue'
import NumberInput from '@/elements/NumberInput.vue'
import TextInput from '@/elements/TextInput.vue'
import GridCard from '@/components/GridCard.vue'
import SearchModal from '@/elements/SearchModal.vue'
import { cloneDeep } from 'lodash'
import { getEmptyOrderProductSize } from '@/models/Order/OrderProductSize'
import { UpwardReturnReasonTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardReturnReasonTypeID'
import store from '@/store'
import { getEmptyPendingReturnOrderProduct } from '@/models/PendingReturnOrderProduct/data'
import { cargoIsRequired } from '@/lib/common/upwardReturnReasonTypes'
import participantsClient from '@/clients/participantsClient'
import { PlayerSearchInfo } from '@/GeneratedTypes/ListInfo/PlayerSearchInfo'
import { DataTableHeader } from 'vuetify/types'

const ADD_TIMEOUT = 3000

interface IDDesc {
  id: string
  desc: string
}

interface DisplayablePlayerSearchInfo extends PlayerSearchInfo {
  prettyName: string
}

export default defineComponent({
  components: {
    NumberInput,
    SelectInput,
    TextInput,
    ReturnProductDetail,
    GridCard,
    Modal,
    SearchModal,
  },
  methods: { filterNA },
  props: {
    product: { type: Object as PropType<OrderProduct>, required: true },
    returnReasons: { type: Array as PropType<UpwardReturnReasonTypeID[]>, required: true },
  },
  setup(props, ctx) {
    const isAdding = ref(false)
    const isModalShown = ref(false)
    const showCargo = ref(false)
    const isPlayerModalShown = ref(false)
    const searchActive = ref(false)
    const searchData = ref<DisplayablePlayerSearchInfo[]>([])

    const colorSizeRef = ref('')
    const sizeRef = ref('')
    const colorRef = ref('')
    const quantityRef = ref(0)
    const returnReasonRef = ref('')
    const cargoRef = ref('')

    const leagueId = computed(() => store.getters.leagueAbstraction.currentItem.id)
    const account = computed(() => store.getters.authorization.firstAccountNumber)
    const upw = computed(() => store.getters.leagueAbstraction.currentItem.upwardLeagueID)

    const internalProduct = ref(props.product)

    const searchTimeout = ref<number | null>(null)

    const headers: DataTableHeader[] = [
      { value: 'prettyName', text: 'Name' },
      { value: 'gender', text: 'Gender' },
      { value: 'typeGradeID', text: 'Grade' },
    ]

    /**
     * Internal product will reflect a product with productColorSize always defined.
     */
    watch(
      () => props.product,
      () => {
        internalProduct.value = cloneDeep(props.product)
        if (!internalProduct.value.productColorSizes || !internalProduct.value.productColorSizes.length) {
          internalProduct.value.productColorSizes = [getEmptyOrderProductSize()] //this shouldn't happen
        }
      }
    )

    const colorSizeLabel = computed(() => {
      let retval = 'Color / Size'
      const coloredProduct = internalProduct.value.productColorSizes?.find((x) =>
        filterNA(x.colorDescription)
      )
      if (!coloredProduct) {
        retval = 'Size'
      }

      return retval
    })

    const colorSizes = computed(() =>
      internalProduct.value.productColorSizes?.length
        ? internalProduct.value.productColorSizes.map(
            (x) =>
              ({
                id: x.typeColorID + '|' + x.typeSizeID,
                desc: filterNA(x.colorDescription) + ' ' + filterNA(x.sizeDescription),
              } as IDDesc)
          )
        : []
    )

    const addDisabled = computed(() => {
      const csCombo = internalProduct.value.productColorSizes!.find(
        (u) => u.typeColorID == colorRef.value && u.typeSizeID == sizeRef.value
      )

      return (
        quantityRef.value <= 0 ||
        !returnReasonRef.value ||
        !colorRef.value ||
        !sizeRef.value ||
        (cargoIsRequired(returnReasonRef.value) && !cargoRef.value) ||
        (csCombo && csCombo.previouslyOrdered < quantityRef.value)
      )
    })

    /**
     * Resets drop down selections and quantity
     */
    function resetDropdownSelection() {
      colorSizeRef.value =
        internalProduct.value.productColorSizes![0].typeColorID +
        '|' +
        internalProduct.value.productColorSizes![0].typeSizeID

      colorRef.value = internalProduct.value.productColorSizes![0].typeColorID
      sizeRef.value = internalProduct.value.productColorSizes![0].typeSizeID
      quantityRef.value = internalProduct.value.orderQuantity || 0
      returnReasonRef.value = ''
      cargoRef.value = ''
    }

    onMounted(() => {
      resetDropdownSelection()
    })

    function selectColorSize(newColorSize: string) {
      // newColorSize is a color/size combo in this format: color|size
      const comboItems = newColorSize.split('|')
      // do we have a valid variation?
      const isSCCombo = internalProduct.value.productColorSizes!.find(
        (u) => u.typeColorID == comboItems[0] && u.typeSizeID == comboItems[1]
      )

      if (!isSCCombo) {
        resetDropdownSelection()
        return
      }
      //we have a valid color/size combo
      colorSizeRef.value = newColorSize
      colorRef.value = comboItems[0]
      sizeRef.value = comboItems[1]
    }

    function selectReturnReason(newReason: string) {
      returnReasonRef.value = newReason
      //TODO check to see if we need to enable cargo
      if (cargoIsRequired(returnReasonRef.value)) {
        showCargo.value = true
      } else {
        //turn off cargo field
        cargoRef.value = ''
        showCargo.value = false
      }
    }

    function select() {
      isAdding.value = true
      ctx.emit('product-update', newReturnItem()) //expects PendingReturnOrderProduct
      quantityRef.value = 0
      cargoRef.value = ''
      selectReturnReason('')
      setTimeout(() => (isAdding.value = false), ADD_TIMEOUT) //cheese, yes I know.  Add a callback or soemthing
    }

    function newReturnItem() {
      return {
        ...getEmptyPendingReturnOrderProduct(),
        leagueID: leagueId.value,
        accountNumber: account.value,
        typeProgramID: internalProduct.value.typeProgramID,
        productID: internalProduct.value.id,
        typeColorID: colorRef.value,
        typeSizeID: sizeRef.value,
        quantity: quantityRef.value,
        typeReturnReasonID: returnReasonRef.value,
        cargo: cargoRef.value,
      }
    }

    function showModal() {
      isModalShown.value = true
    }

    function showPlayerModal() {
      isPlayerModalShown.value = true
    }

    function getFirstNameLastName(s: string) {
      let searchSegments = s.split(',')
      let lastFirstSearch = true
      if (!searchSegments || searchSegments.length <= 1) {
        searchSegments = s.split(' ')
        lastFirstSearch = false
      }
      let endSegment = ''
      if (searchSegments.length > 1) {
        endSegment = searchSegments.slice(1).join(' ')
      }
      if (lastFirstSearch) {
        return [endSegment.trim(), searchSegments[0].trim()]
      }
      return [searchSegments[0].trim(), endSegment.trim()]
    }

    function updateSearch(s: string) {
      //4656 - make them type at least 3 characters before searching
      if (s.trim().length > 2) {
        if (searchTimeout.value) {
          clearTimeout(searchTimeout.value)
        }

        searchTimeout.value = window.setTimeout(async () => {
          await doSearch(s)
        }, 1000)
      }
    }

    async function doSearch(s: string) {
      const [first, last] = getFirstNameLastName(s)

      if (!searchActive.value) {
        searchActive.value = true
        try {
          const result = await participantsClient.search({
            leagueID: upw.value!,
            orSearch: true,
            includePastLeagues: false,
            first: first,
            last: last,
          })

          searchData.value = result.map((p) => {
            return {
              ...p,
              prettyName: `${p.firstName} ${p.lastName}`,
            } as DisplayablePlayerSearchInfo
          })
        } finally {
          searchActive.value = false
          searchTimeout.value = null
        }
      }
    }

    function searchSelected(p: PlayerSearchInfo) {
      if (cargoRef.value && cargoRef.value.length > 0) {
        cargoRef.value += ', '
      }

      cargoRef.value += `${p.firstName} ${p.lastName}`
    }

    return {
      select,
      selectColorSize,
      isAdding,
      isModalShown,
      showModal,
      isPlayerModalShown,
      showPlayerModal,
      showCargo,
      colorSizeLabel,
      colorSizes,
      colorSizeRef,
      quantityRef,
      internalProduct,
      productSummary: false,
      addDisabled,
      returnReasonRef,
      selectReturnReason,
      cargoRef,
      headers,
      searchActive,
      searchData,
      updateSearch,
      searchSelected,
      colorRef, //just so we can see in dev tools
      sizeRef, //just so we can see in dev tools
    }
  },
})
