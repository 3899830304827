































































import { computed, defineComponent, onMounted, ref, watch } from '@vue/composition-api'
import dayjs from 'dayjs'

import Loading from '@/elements/Loading.vue'
import PageContent from '@/components/PageContent.vue'
import Alert from '@/components/Alert.vue'
import ProductSelect from '@/components/Orders/ProductSelect.vue'
import ReturnProductSelector from '@/views/Programs/Orders/vues/components/ReturnProductSelector.vue'
import ReturnOrderCart from '@/components/Orders/ReturnOrderCart.vue'

import { PendingReturnOrderProduct } from '@/GeneratedTypes/PendingReturnOrderProduct'
import { PendingReturnOrderProductInfo } from '@/GeneratedTypes/ListInfo/PendingReturnOrderProductInfo'
import { UpwardReturnReasonTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardReturnReasonTypeID'
import { UpwardOpportunityTypes } from '@/lib/common/upwardOpportunityTypes'
import { OrderProduct } from '@/models/Order/OrderProduct'

import store from '@/store'
import ordersClient from '@/clients/ordersClient'

export default defineComponent({
  components: {
    ProductSelect,
    ReturnProductSelector,
    ReturnOrderCart,
    Alert,
    Loading,
    PageContent,
  },
  setup() {
    const currentOrderProducts = ref<OrderProduct[]>([])
    const pendingReturnProducts = ref<PendingReturnOrderProductInfo[]>([])
    const loading = ref(false)
    const showRmaError = ref(false)
    const raNumber = ref('')
    const returnReasons = ref<UpwardReturnReasonTypeID[]>([])
    const generateDisabled = ref(true)

    const upwId = computed(() => store.getters.leagueAbstraction.currentItem.upwardLeagueID ?? '')
    const account = computed(() => store.getters.authorization.firstAccountNumber)

    const confirmationContentfulID = ref('returnsOrderConfirmation')
    const expiredContentfulId = ref('returnsOrderExpired')

    onMounted(async () => {
      loading.value = true
      try {
        store.dispatch.returnReasonTypes.fetchAll(false) //get these cached up
        store.dispatch.sizeTypes.fetchAll(false) //get these cached up
        store.dispatch.colorTypes.fetchAll(false) //get these cached up
        returnReasons.value = store.getters.returnReasonTypes.allItems
        pendingReturnProducts.value = await ordersClient.getPendingReturnProductList(
          upwId.value,
          account.value
        )
        currentOrderProducts.value = await ordersClient.getReturnsOrderProductList(upwId.value, account.value)
      } finally {
        loading.value = false
      }
    })

    const isReturnsExpired = computed(() => {
      if (expirationDate.value) {
        return dayjs().diff(expirationDate.value, 'day', true) > 15
      }
      return false
    })

    const expirationDate = computed(() => {
      switch (store.getters.leagueAbstraction.programType) {
        case UpwardOpportunityTypes.LEAGUE:
          return store.getters.leagues.currentItem.seasonEndDate
        case UpwardOpportunityTypes.CAMP:
          return store.getters.camps.currentItem.campEndDate
        case UpwardOpportunityTypes.CONNECT:
          return store.getters.connects.currentItem.connectDate
        case UpwardOpportunityTypes.CLINIC:
          return store.getters.clinics.currentItem.clinicDate
        default:
          null
      }
    })

    watch(
      () => store.getters.returnReasonTypes.allItems,
      () => {
        returnReasons.value = store.getters.returnReasonTypes.allItems
      }
    )

    watch(
      () => pendingReturnProducts.value,
      () => {
        generateDisabled.value = pendingReturnProducts.value.length <= 0
      }
    )

    async function add(x: PendingReturnOrderProduct) {
      await ordersClient.addOrUpdate_PendingReturnProduct(upwId.value, x).then(async () => {
        pendingReturnProducts.value = await ordersClient.getPendingReturnProductList(
          upwId.value,
          account.value
        )
      })
    }

    async function remove(x: PendingReturnOrderProductInfo) {
      await ordersClient
        .deletePendingReturnProductByIndex(upwId.value, account.value, x.id)
        .then(async () => {
          pendingReturnProducts.value = await ordersClient.getPendingReturnProductList(
            upwId.value,
            account.value
          )
        })
    }

    async function generateRA() {
      if (!upwId) {
        showRmaError.value = true
        return
      }

      showRmaError.value = false
      loading.value = true
      try {
        const rma = await ordersClient.generateRMA(upwId.value, account.value, null) //TODO add support for order note from user
        raNumber.value = rma
      } finally {
        loading.value = false
      }
    }

    return {
      currentOrderProducts,
      add,
      remove,
      loading,
      showRmaError,
      generateRA,
      raNumber,
      generateDisabled,
      returnReasons,
      pendingReturnProducts,
      confirmationContentfulID,
      isReturnsExpired,
      expiredContentfulId,
    }
  },
})
